import { useEffect, useState } from "react";
import { Button, TextField } from '@mui/material';
import Flex from "../../../global/Flex.js";
import colors from "../../../../colors.js";
import api from "../../../../api.js";
import { ClipLoader } from 'react-spinners';

export default function() {
  const [encounterUuid, setEncounterUuid] = useState('');
  const [rawData, setRawData] = useState(null);
  const [kelaData, setKelaData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async function() {
      const rawData = await api.getRawDataByEncounterUUID({ encounter_uuid: '5351d2bd-d47b-488a-bcef-e0015b2281bc' });
      setRawData(rawData);
      const kelaData = await api.getEncounterByUUID({ encounter_uuid: '5351d2bd-d47b-488a-bcef-e0015b2281bc' });
      console.log(kelaData);
      setKelaData(kelaData);
    }());
  }, []);

  async function getRawDataByEncounterUUID() {
    try {
      if (!encounterUuid) throw Error('Encounter UUID required');
      setIsLoading(true);
      const data = await api.getRawDataByEncounterUUID({ encounter_uuid: encounterUuid });
      console.log(data);
      setRawData(data);
    } catch(err) {
      setError(err.message);
      console.log(err.message)
    } finally { setIsLoading(false) }
  }

  return (
    <Flex f={1} column style={{ alignSelf: 'stretch' }}>
      <Flex column style={{ padding: 20 }}>
        <div style={{ marginBottom: 20, color: colors.offwhite }}>Enter a KelaHealth Encounter UUID to lookup the raw provider data for that resource</div>
        <Flex aic>

          <TextField 
            label='Encounter UUID'
            value={encounterUuid}
            onChange={({ target }) => setEncounterUuid(target.value)}
            sx={{
              width: 500,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: colors.white, // Default border color
                },
                '&:hover fieldset': {
                  borderColor: colors.white, // Border color when hovered
                },
                '&.Mui-focused fieldset': {
                  borderColor: colors.white, // Border color when focused
                },
              },
              '& .MuiInputLabel-root': {
                color: colors.white, // Label color
              },
              '& .MuiInputBase-input': {
                color: colors.white, // Text color inside the input
              }
            }}
            InputLabelProps={{
              style: { color: colors.offwhite }, // Label color when focused
            }}
            InputProps={{
              style: { color: colors.white }, // Text color when typing
            }}
          />
          <Button 
            variant="contained" 
            style={{ height: 55, marginLeft: 5, backgroundColor: colors.secondary, color: colors.dark }}
            onClick={getRawDataByEncounterUUID}>SEARCH</Button>
            {isLoading && <Flex style={{ marginLeft: 10 }}> 
              <ClipLoader color={colors.secondary} size={30} />
            </Flex>}
          
        </Flex>
      </Flex>

            
      {rawData &&
        <Flex style={{ marginLeft: 20 }}>
          <Flex column aisb>
            {Object.entries(rawData).map(kvp => (
              <KVP objKey={kvp[0]} objValue={kvp[1]} />
            ))}
          </Flex>
        </Flex>
      }
    </Flex>
  );
}

function KVP({ objKey, objValue }) {
  return (
    <Flex jcsb style={{ maxWidth: 400, marginBottom: 6 }}>
      <span style={{ color: colors.lightest, fontWeight: 'bold', marginRight: 20 }}>{objKey}</span>
      <span style={{ textAlign: 'right', wordWrap: 'break-word', maxWidth: 300 }}>{objValue}</span>
    </Flex>
  )
}
