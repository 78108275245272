import axios from 'axios';
import decodeJWT from "jwt-decode";
import { setSession, closeSession } from './sessions.js';

const { 
  REACT_APP_EXTERNAL_API_ADMIN_URL,
  REACT_APP_AUTHENTICATION_SERVICE_URL
} = process.env;

class Api {
  constructor() {
    this.client_api = axios.create({ baseURL: REACT_APP_EXTERNAL_API_ADMIN_URL });
    this.client_auth = axios.create({ baseURL: REACT_APP_AUTHENTICATION_SERVICE_URL });
    this.client_api.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
      return config;
    }, e => Promise.reject(e));
    this.client_api.interceptors.response.use(res => res,
      async res => {
        if (res.response?.status !== 401) return Promise.reject(res);
        const freshToken = await this.refresh();
        res.config.headers.Authorization = `Bearer ${freshToken}`;
        return axios.request(res.config);
    });
    this.client_auth.interceptors.response.use(res => res,
      res => {
        if (res.config?.url?.includes('/login')) return Promise.reject(res);
        console.log('Unauthorized');
        closeSession();
        location.reload();
        return Promise.reject(res);
      })
  }

  async login({ email, password }) {
    const { data } = await this.client_auth.post('/login', { email, password });
    if (!data.access_token || !data.refresh_token) throw Error('Unauthorized');
    const decodedToken = decodeJWT(data.access_token);
    if (!decodedToken.is_admin) throw Error('Unauthorized');
    setSession({
      access_token: data.access_token,
      refresh_token: data.refresh_token,
      organization_identifier: decodedToken.organization_identifier,
      user_identifier: decodedToken.name,
      portal: 'admin'
    });
  }

  async refresh() {
    const refreshToken = localStorage.getItem('refresh_token');
    const { data } = await this.client_auth.post('/refresh', null, { headers: { Authorization: `Bearer ${refreshToken}`}});
    if (!data.access_token) throw Error('Unauthorized');
    const decodedToken = decodeJWT(data.access_token);
    if (!decodedToken.is_admin) throw Error('Unauthorized');
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);
    return data.access_token;
  }

  async logout() {
    const refresh_token = localStorage.getItem('refresh_token');
    await this.client_auth.post('/logout', null, { headers: { Authorization: `Bearer ${refresh_token}`}});
  }

  async getOrganizationDetails() {
    const { data } = await this.client_api.get('/admin/organization-details');
    return data;
  }

  async getProviderData({ provider_name, source_identifier } = { provider_name: null, source_identifier: null }) {
    const { data } = await this.client_api.get('/admin/provider-data', { provider_name, source_identifier });
    return data;
  }

  async getProviders() {
    const { data } = await this.client_api.get('/admin/providers');
    return data;
  }

  async getDataOverview({ provider_id }) {
    const { data } = await this.client_api.get(`/admin/get-providers?provider_id=${provider_id}`);
    return data;
  }

  async getLinkableSurgeons({ organization_id }) {
    const { data } = await this.client_api.get(`/admin/surgeons-linkable?organization_id=${organization_id}`);
    return data;
  }

  async createUser(newUser) {
    const { data } = await this.client_api.post('/admin/users', newUser);
    return data;
  }

  async updateUser({ user_id, ...stagedChanges }) {
    const { data } = await this.client_api.put(`/admin/users/${user_id}`, stagedChanges);
    return data;
  }

  async getSurgeonData({ surgeon_id }) {
    const { data } = await this.client_api.get(`/admin/surgeons/${surgeon_id}`);
    return data;
  }

  async getSessions() {
    const { data } = await this.client_api.get(`/admin/sessions`);
    return data;
  }

  async getPipelineEvents() {
    const { data } = await this.client_api.get(`/admin/pipeline-events`);
    return data;
  }

  async getPipelineEventById({ pipeline_event_id }) {
    const { data } = await this.client_api.get(`/admin/pipeline-events/${pipeline_event_id}`);
    return data;
  }

  async getStagedProviderData() {
    const { data } = await this.client_api.get(`/admin/staged-provider-data`);
    return data;
  }

  async startIntake() {
    const { data } = await this.client_api.post(`/admin/start-intake`);
    return data;
  }

  async startDataUpdate(params) {
    const { data } = await this.client_api.post(`/admin/update-provider-data`, params);
    return data;
  }

  async getPipelineDetails() {
    const { data } = await this.client_api.get(`/admin/pipeline-info`);
    return data;
  }

  async getInternalDataReport({ provider_id, provider_data_id, provider_name, filename, type }) {
    const { data } = await this.client_api.post(`/admin/provider-data/report`, { provider_id, provider_data_id, provider_name, filename, type });
    return data;
  }

  async getAPIRequests({ organization_id }) {
    const { data } = await this.client_api.get(`/admin/api-requests?organization_id=${organization_id}`);
    return data;
  }

  async getRawDataByEncounterUUID({ encounter_uuid }) {
    const { data } = await this.client_api.get(`admin/raw-data/${encounter_uuid}`);
    return data;
  }

  async getEncounterByUUID({ encounter_uuid }) {
    const { data } = await this.client_api.get(`admin/encounters/${encounter_uuid}`);
    return data;
  }
}

const api = new Api();

class MockApi {
  async sleep(ms = 1000) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async login({ email, password }) {
    if (!email || !password) throw Error('Unauthorized');
    setSession({
      access_token: 'fake-token-access',
      refresh_token: 'fake-token-refresh',
      organization_identifier: 'demo',
      user_identifier: email.split('@')[0],
      portal: 'admin'
    });
  }

  async logout(){
    //Does nothing
  } 

  async getOrganizationDetails() {
    return [
      {
          "organization_id": 1,
          "organization_identifier": "admin",
          "users": [
              {
                  "id": 1,
                  "email": "root@kelahealth.com",
                  "name": "root",
                  "last_session_date": "2023-04-29T17:53:09.256035"
              }
          ],
          "organization_providers": []
      },
      {
          "organization_id": 2,
          "organization_identifier": "aah",
          "users": [
              {
                  "id": 2,
                  "email": "kelahealth@aah.com",
                  "name": "Brooks",
                  "last_session_date": "2023-03-08T11:40:20.464374"
              }
          ],
          "organization_providers": []
      },
      {
          "organization_id": 3,
          "organization_identifier": "mock",
          "users": [
              {
                  "id": 4,
                  "email": "bpike7@gmail.com",
                  "name": "Brooks Pike",
                  "last_session_date": "2023-03-15T18:43:52.094353"
              },
              {
                  "id": 3,
                  "email": "kelahealth@mock.com",
                  "name": "Kelahealth Mock",
                  "last_session_date": "2023-04-21T13:04:09.506291"
              }
          ],
          "organization_providers": [
              {
                  "provider": "mock",
                  "permission": "owner"
              }
          ]
      },
      {
          "organization_id": 4,
          "organization_identifier": "demo",
          "users": [
              {
                  "id": 8,
                  "email": "bora@kelahealth.com",
                  "name": "Bora Chang",
                  "last_session_date": "2023-04-26T16:34:16.715781"
              },
              {
                  "id": 9,
                  "email": "danielle@kelahealth.com",
                  "name": "Danielle Magrogan",
                  "last_session_date": "2023-04-28T20:32:58.815017"
              },
              {
                  "id": 12,
                  "email": "dylan@kelahealth.com",
                  "name": "Dylan Rodriquez",
                  "last_session_date": "2023-04-27T20:50:45.499484"
              },
              {
                  "id": 11,
                  "email": "gianfranco@kelahealth.com",
                  "name": "Gianfranco Colombi",
                  "last_session_date": "2023-04-26T17:48:15.814091"
              },
              {
                  "id": 7,
                  "email": "jeanpierre@kelahealth.com",
                  "name": "Jean-Pierre Schott",
                  "last_session_date": "2023-04-26T16:26:50.474421"
              },
              {
                  "id": 6,
                  "email": "kelahealth@demo.com",
                  "name": "Kelahealth Demo",
                  "last_session_date": "2023-04-29T05:18:18.002531"
              },
              {
                  "id": 10,
                  "email": "marina@kelahealth.com",
                  "name": "Marina Tai",
                  "last_session_date": "2023-04-27T19:52:09.984203"
              },
              {
                  "id": 13,
                  "email": "steve@kelahealth.com",
                  "name": "Steve Ditto",
                  "last_session_date": "2023-04-26T16:47:15.165431"
              }
          ],
          "organization_providers": [
              {
                  "provider": "aah",
                  "permission": "owner"
              }
          ]
      }
  ]
  }

  async getProviders() {
    return [
      {
        id: 1,
        name: 'aah'
      },
      {
        id: 2,
        name: 'mock'
      }
    ]
  }

  async getDataOverview() {
    return [
      {
        service_line: 'general_surgery',
        counts: {
          unfiltered: 1000,
          filtered: 900
        }
      },
      {
        service_line: 'thoracic',
        counts: {
          unfiltered: 500,
          filtered: 430
        }
      }
    ]
  }
}

export default api;

const mock = new MockApi();
